<template>
  <div class="d-flex h-100">
    <b-col class="d-flex flex-column justify-content-between">
      <img
        v-for="i in 10 - roundedPrimaryValue" 
        :key="i"
        :src="require(`@/assets/images/icons/rectangle-outline.svg`)"
        alt=""
        style="height: 14px; margin-bottom: 2px;"
      >
      <img
        v-for="i in roundedPrimaryValue" 
        :key="i"
        :src="require(`@/assets/images/icons/rectangle-fill-${primaryColor}.svg`)"
        alt=""
        style="height: 14px; margin-bottom: 2px;"
      >
      <img
        :src="require(`@/assets/images/icons/brake-primary.svg`)"
        alt=""
        :class="brakePrimaryClass"
        style="height: 22px"
      >
    </b-col>
    <b-col class="d-flex flex-column justify-content-between">
      <img
        v-for="i in 10 - roundedSecondaryValue" 
        :key="i"
        :src="require(`@/assets/images/icons/rectangle-outline.svg`)"
        alt=""
        style="height: 14px; margin-bottom: 2px;"
      >
      <img
        v-for="i in roundedSecondaryValue" 
        :key="i"
        :src="require(`@/assets/images/icons/rectangle-fill-${secondaryColor}.svg`)"
        alt=""
        style="height: 14px; margin-bottom: 2px;"
      >
      <img
        :src="require(`@/assets/images/icons/brake-secondary.svg`)"
        alt=""
        :class="brakeSecondaryClass"
        style="height: 22px"
      >
    </b-col>
  </div>
</template>

<script>
export default {
  props: {
    primaryValue: {
      type: Number,
      default: 0,
    },
    secondaryValue: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    roundedPrimaryValue() {
      return Math.round(this.primaryValue) > 10 ? 10 : Math.round(this.primaryValue)
    },
    roundedSecondaryValue() {
      return Math.round(this.secondaryValue) > 10 ? 10 : Math.round(this.secondaryValue)
    },
    primaryColor() {
      if(this.roundedPrimaryValue > 6) return 'green'
      else if(this.roundedPrimaryValue > 4) return 'orange'
      else if(this.roundedPrimaryValue > 1) return 'red'
      return 'black'
    },
    secondaryColor() {
      if(this.roundedSecondaryValue > 6) return 'green'
      else if(this.roundedSecondaryValue > 4) return 'orange'
      else if(this.roundedSecondaryValue > 1) return 'red'
      return 'black'
    },
    brakePrimaryClass() {
      if(this.roundedPrimaryValue > 6) return 'indicator-green'
      else if(this.roundedPrimaryValue > 4) return 'indicator-orange'
      else if(this.roundedPrimaryValue > 1) return 'indicator-red'
      return 'black'
    },
    brakeSecondaryClass() {
      if(this.roundedSecondaryValue > 6) return 'indicator-green'
      else if(this.roundedSecondaryValue > 4) return 'indicator-orange'
      else if(this.roundedSecondaryValue > 1) return 'indicator-red'
      return 'black'
    },
  },
};
</script>
<style scoped>
.indicator-green {
  filter: invert(37%) sepia(99%) saturate(1002%) hue-rotate(86deg) brightness(99%) contrast(104%);
}
.indicator-orange {
  filter: invert(51%) sepia(99%) saturate(2638%) hue-rotate(1deg) brightness(104%) contrast(104%);
}
.indicator-red {
  filter: invert(39%) sepia(95%) saturate(7089%) hue-rotate(357deg) brightness(90%) contrast(132%);
}
</style>