<template>
  <div v-if="!isQuester">
    <b-row class="d-flex row-padding mb-1 gap-1">
      <b-col class="custom-col h-100">
        <div
          class="ms-2" 
          style="font-size: 16px;"
        >
          <span style="font-weight: 600;">Trip Estimation</span>
        </div>
      </b-col>
    </b-row>
    <b-row class="row-padding mb-1 gap-1">
      <b-col class="custom-col">
        <div class="d-flex ms-2 py-1">
          <img
            :src="require(`@/assets/images/icons/trip-distance.svg`)"
            title="Trip Distance"
            style="height: 22px;"
            alt=""
            class="my-auto"
          >
          <span class="ms-2 my-auto">
            {{ distance }} km
          </span>
        </div>
      </b-col>
      <b-col class="custom-col">
        <div class="d-flex ms-2 py-1">
          <img
            :src="require(`@/assets/images/icons/trip-duration.svg`)"
            title="Trip Duration"
            style="height: 22px;"
            alt=""
            class="my-auto"
          >
          <span class="ms-2 my-auto">
            {{ duration }}
          </span>
        </div>
      </b-col>
    </b-row>
  </div>
  <div v-else>
    <b-row class="d-flex row-padding mb-1 gap-1">
      <b-col class="custom-col h-100">
        <div
          class="ms-2" 
          style="font-size: 16px;"
        >
          <span style="font-weight: 600;">Trip Fuel Efficiency :</span> {{ fuelEfficiency == '-' ? fuelEfficiency : `${fuelEfficiency} km/L` }}
        </div>
      </b-col>
      <b-col
        class="custom-col d-flex"
        md="2"
      >
        <img
          :src="fuelEfficiencyIcon"
          style="height: 22px;"
          alt=""
          class="m-auto"
        >
      </b-col>
    </b-row>

    <b-row class="row-padding mb-1 gap-1">
      <!-- Trip Data -->
      <b-col 
        v-for="(data, idx) in tripData"
        :key="idx"
        class="custom-col"
      >
        <div
          v-for="(item, index) in data"
          :key="index"
          class="d-flex ms-2 pb-1"
        >
          <img
            :src="require(`@/assets/images/icons/${item.icon}.svg`)"
            :title="item.title"
            style="height: 22px;"
            alt=""
            class="my-auto"
          >
          <span class="ms-2 my-auto">
            {{ item.value }} {{ item.unit }}
          </span>
        </div>
      </b-col>
      <!-- Emission -->
      <b-col
        class="custom-col d-flex"
        md="2"
      >
        <div class="m-auto">
          <div class="text-center">
            <img
              :src="require(`@/assets/images/icons/trip-emission.svg`)"
              :title="`Carbon Emission Coefficient = ${fleetDetail.emission_coefficient ?? '-'} kgCO2/L`"
              style="height: 28px;"
              alt=""
              class="my-auto"
            >
          </div>
          <div
            class="text-center fw-bold"
            style="font-size: 18px"
          >
            {{ emission }}
          </div>
          <div class="text-center">
            kg CO<sub>2</sub>
          </div>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { differenceInMinutes, minutesToHours } from 'date-fns';
import { formatDuration } from '@/utils/helper';
export default {
  props: {
    fleetDetail: {
      type: Object,
      default() {
        return {}
      } 
    },
    filter: {
      type: Object,
      default() {
        return {}
      } 
    },
    isQuester: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      isLoading: false,
      distanceResult: {},
      firstPayload: {},
      lastPayload: {},
    }
  },
  computed: {
    duration() {
      if(this.idleHours && this.operationHours) {
        return formatDuration(Number(this.idleHours) + Number(this.operationHours))
      } else if(this.firstPayload?.created_at && this.lastPayload?.created_at) {
          const duration = Math.abs(differenceInMinutes(new Date(this.firstPayload.created_at), new Date(this.lastPayload.created_at)))
          const hours = minutesToHours(duration)
          const minutes = duration % 60

          const hourString = hours ? `${hours} h` : ''
          const minuteString = minutes ? ` ${minutes} m` : ''
          return hourString + minuteString
      } return '-'
      
    },
    distance() {
      const startValue = this.firstPayload?.can_bus?.total_vehicle_distance ?? ''
      const lastValue = this.lastPayload?.can_bus?.total_vehicle_distance ?? ''
      if(startValue && lastValue) {
        return Number(lastValue - startValue).toFixed(2)
      } else if (this.distanceResult) {
        const distance = this.distanceResult?.total_distance ?? 0
        return Number(distance).toFixed(2)
      }
      return 0
    },
    operationFuel() {
      const startValue = this.firstPayload?.can_bus?.engine_total_fuel_used ?? ''
      const lastValue = this.lastPayload?.can_bus?.engine_total_fuel_used ?? ''
      if(startValue && lastValue) {
        return Number(lastValue - startValue).toFixed(2)
      } return '-'
    },
    operationHours() {
      const startValue = this.firstPayload?.can_bus?.engine_total_hour_operation ?? ''
      const lastValue = this.lastPayload?.can_bus?.engine_total_hour_operation ?? ''
      if(startValue && lastValue) {
        return Number(lastValue - startValue).toFixed(2)
      } return null
    },
    idleFuel() {
      const startValue = this.firstPayload?.can_bus?.total_idle_fuel_used ?? ''
      const lastValue = this.lastPayload?.can_bus?.total_idle_fuel_used ?? ''
      if(startValue && lastValue) {
        return Number(lastValue - startValue).toFixed(2)
      } return '-'
    },
    idleHours() {
      const startValue = this.firstPayload?.can_bus?.total_idle_hours ?? ''
      const lastValue = this.lastPayload?.can_bus?.total_idle_hours ?? ''
      if(startValue && lastValue) {
        return Number(lastValue - startValue).toFixed(2)
      } return null
    },
    tripData() {
      return [
        [
          { icon:'trip-distance', title: 'Trip Distance', value: this.distance, unit: 'km' },
          { icon:'trip-fuel-operation', title: 'Trip Engine Fuel Used', value: this.operationFuel, unit: 'L' },
          { icon:'trip-fuel-idle', title: 'Trip Idle Fuel Used', value: this.idleFuel, unit: 'L' },
        ], [
          { icon:'trip-duration', title: 'Trip Duration', value: this.duration, unit: '' },
          { icon:'trip-hour-operation', title: 'Trip Engine Hour Operation', value: formatDuration(this.operationHours), unit: '' },
          { icon:'trip-hour-idle', title: 'Trip Idle Hour', value: formatDuration(this.idleHours), unit: '' },
        ]
      ]
    },
    fuelEfficiency() {
      const totalFuelUsed = Number(this.idleFuel) + Number(this.operationFuel)
      const fuelEfficiency = Number(this.distance / totalFuelUsed).toFixed(2)
      return Number(fuelEfficiency) ? fuelEfficiency : '-'
    },
    fuelEfficiencyIcon() {
      const bestThreshold = 4.5
      const goodThreshold = 4.0
      if(Number(this.fuelEfficiency) > bestThreshold) return require('@/assets/images/icons/trip-fuel-coaching-2.svg')
      else if(Number(this.fuelEfficiency) >= goodThreshold) return require('@/assets/images/icons/trip-fuel-coaching-1.svg')
      else return require('@/assets/images/icons/trip-fuel-coaching-0.svg')
    },
    emission() {
      const emissionPerLitre = this.fleetDetail.emission_coefficient ?? 0
      const totalFuel = Number(this.idleFuel) + Number(this.operationFuel)
      if(Number(totalFuel)) {
        return (emissionPerLitre * totalFuel).toFixed(2)
      } return '-'
    }
  },
  watch: {
    filter: {
      immediate: false,
      deep: true,
      handler() {
        this.getFleetDistance()
        this.getPayloads()
      }  
    }
  },
  methods: {
    async getFleetDistance() {
      this.isLoading = true
      try {
        const { data } = await this.$api.getDeviceDistance(this.fleetDetail.id, {
          start_date: new Date(this.filter.start_date).toISOString(),
          end_date: new Date(this.filter.end_date).toISOString(),
          resolution: 5
        });
        this.distanceResult = data;
      } catch (error) {
        console.log(error);
      } finally {
        this.isLoading = false
      }
    },
    async getPayloads() {
      this.isLoading = true
      try {
        const first = await this.$api.getDeviceLocation(this.fleetDetail.id, { 
          start_date: new Date(this.filter.start_date).toISOString(),
          end_date: new Date(this.filter.end_date).toISOString(),
          page: 1,
          per_page: 1,
          sort_by: 'created_at',
          direction: 'asc'
        });
        if (first.data.data) {
          this.firstPayload = first.data.data[0]
        }

        const last = await this.$api.getDeviceLocation(this.fleetDetail.id, { 
          start_date: new Date(this.filter.start_date).toISOString(),
          end_date: new Date(this.filter.end_date).toISOString(),
          page: 1,
          per_page: 1,
          sort_by: 'created_at',
          direction: 'desc'
        });
        if (last.data.data) {
          this.lastPayload = last.data.data[0]
        }
      } catch (error) {
        console.log(error);
      } finally {
        this.isLoading = false
      }
    },
  },
};
</script>