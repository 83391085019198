<template>
  <div
    ref="content"
    style="width: 350px;"
  >
    <h5 class="fw-bold">
      {{ fleetDetail.vehicle_number }} {{ fleetDetail.driver_name ? ` - ` : `` }} {{ fleetDetail.driver_name }}
    </h5>
    <div>
      <div class="d-flex mb-1">
        <b-col class="custom-col">
          <div class="d-flex pb-1">
            <img
              src="@/assets/images/icons/datetime.svg"
              alt=""
              class="my-auto ms-2"
              style="height: 22px;"
            >
            <span class="ms-2 my-auto">
              {{ datetime }}
            </span>
          </div>
          <div class="d-flex pb-1">
            <img
              src="@/assets/images/icons/address.svg"
              alt=""
              class="my-auto ms-2"
              style="height: 22px;"
            >
            <span
              v-if="!isLoading"
              class="mx-2 my-auto"
            >
              {{ address }}
            </span>
            <b-spinner
              v-else
              small
              class="mx-2 my-auto"
            />
          </div>
          <div class="d-flex pb-1">
            <img
              src="@/assets/images/icons/location.svg"
              alt=""
              class="my-auto ms-2"
              style="height: 22px;"
            >
            <span class="ms-2 my-auto">
              {{ location }}
            </span>
          </div>
          <!-- Not Quester Altitude Payload -->
          <div
            v-if="trackerType != 'quester'"
            class="d-flex pb-1"
          >
            <img
              src="@/assets/images/icons/altitude.svg"
              alt=""
              class="my-auto ms-2"
              style="height: 22px;"
            >
            <span class="ms-2 my-auto">
              {{ payload.altitude }} {{ parsePayloadKey('altitude').unit }}
            </span>
          </div>
          <!-- SMF Vehicle Speed -->
          <div
            v-if="trackerType == 'smf'"
            class="d-flex pb-1"
          >
            <img
              src="@/assets/images/icons/vehicle-speed.svg"
              alt=""
              class="my-auto ms-2"
              style="height: 22px;"
            >
            <span class="ms-2 my-auto">
              {{ payload.gps_vehicle_speed }} {{ parsePayloadKey('gps_vehicle_speed').unit }}
            </span>
          </div>
          <!-- SMF Ignition -->
          <div
            v-if="trackerType == 'smf'"
            class="d-flex pb-1"
          >
            <img
              :src="payload.ignition ? `${require(`@/assets/images/icons/ignition-on.svg`)}` : `${require(`@/assets/images/icons/ignition-off.svg`)}`"
              title="Ignition"
              alt=""
              class="my-auto ms-2"
            >
            <span class="ms-2 my-auto">
              {{ payload.ignition ? 'Ignition On' : 'Ignition Off' }}
            </span>
          </div>
        </b-col>
      </div>
      <!-- Quester Payload -->
      <div
        v-if="trackerType == 'quester'"
        class="d-flex mb-1 gap-1"
      >
        <b-col 
          v-for="(data, idx) in canBusPayload"
          :key="idx"
          class="custom-col"
        >
          <div
            v-for="(item, index) in data"
            :key="index"
            class="d-flex ms-2 pb-1"
          >
            <img
              :src="require(`@/assets/images/icons/${item.icon}.svg`)"
              :title="item.title ? item.title : parsePayloadKey(item.payloadKey).name"
              style="height: 22px;"
              alt=""
              class="my-auto"
            >
            <span class="ms-2 my-auto">
              {{ item.value }} {{ parsePayloadKey(item.payloadKey).unit }}
            </span>
          </div>
        </b-col>
      </div>
      <!-- Quester Indicators -->
      <div
        v-if="trackerType == 'quester'"
        class="d-flex mb-1 gap-1"
      >
        <b-col class="d-flex pb-1 custom-col">
          <img
            :src="payload.ignition ? `${require(`@/assets/images/icons/ignition-on.svg`)}` : `${require(`@/assets/images/icons/ignition-off.svg`)}`"
            title="Ignition"
            alt=""
            class="my-auto mx-auto"
          >
        </b-col>
        <b-col class="d-flex pb-1 custom-col">
          <img
            :src="payload.parking_brake ? `${require(`@/assets/images/icons/parking-brake-on.svg`)}` : `${require(`@/assets/images/icons/parking-brake-off.svg`)}`"
            title="Parking Brake"
            alt=""
            class="my-auto mx-auto"
          >
        </b-col>
        <b-col class="d-flex pb-1 custom-col">
          <img
            :src="require(`@/assets/images/icons/${coolantTempIcon}.svg`)"
            title="Coolant Temperature"
            alt=""
            class="my-auto mx-auto"
          >
        </b-col>
        <b-col class="d-flex pb-1 custom-col">
          <img
            :src="malfunctionIcon"
            :title="malfunctionLabel"
            alt=""
            class="my-auto mx-auto"
          >
        </b-col>
      </div>
      <div class="mt-2">
        <b-button
          variant="outline-primary w-100"
          @click="$emit('trace-fleet', fleetDetail)"
        >
          Trace Fleet
        </b-button>
      </div>
    </div>
  </div>  
</template>

<script>
import { utcOffset } from '@/utils/constant';
import { formatDateTime, parsePayloadKey } from '@/utils/helper';

export default {
  props: {
    fleetDetail: {
      type: Object,
      default() {
        return {}
      }
    }
  },
  data() {
    return {
      utcOffset,
      leftColumn: [
        { key: 'vehicle-speed', payloadKey: 'wheel_based_vehicle_speed', title:'Vehicle Speed'},
        { key: 'fuel-level', payloadKey: 'fuel_level', title:'Fuel Level'},
        { key: 'coolant-temp', payloadKey: 'engine_coolant_temperature', title: 'Coolant Temperature'},
      ],
      address: '',
      isLoading: false,
    }
  },
  computed: {
    trackerType() {
      if(this.payload?.wheel_based_vehicle_speed != null) {
        return 'quester'
      } else if (this.payload?.ignition != null) {
        return 'smf'
      } return 'teseo'
    },
    location() {
      if(this.payload.location?.coordinates) {
        const latitude = this.payload.location?.coordinates[1]
        const longitude = this.payload.location?.coordinates[0]
        return `${latitude}, ${longitude}`
      } return ''
    },
    organizationId() {
      return this.$store.state.organization.selectedOrganization;
    },
    datetime() {
      return this.fleetDetail?.datetime ? formatDateTime(this.fleetDetail?.datetime, true) : ''
    }, 
    payload() {
      return this.fleetDetail?.payload ?? {}
    },
    coolantTempIcon() {
      if(this.payload?.engine_coolant_temperature) {
        if(Number(this.payload?.engine_coolant_temperature) > 119) return 'coolant-temp-hot'
        else if(Number(this.payload?.engine_coolant_temperature) > 31) return 'coolant-temp-warm'
        else return 'coolant-temp-cool'
      } return 'coolant-temp'
    },
    malfunctionIcon() {
      if(Number(this.payload?.malfunction_indicator_lamp_status) == 1) return require(`@/assets/images/icons/malfunction-indicator-on.svg`)
      else if(Number(this.payload?.malfunction_indicator_lamp_status) == 2) return require(`@/assets/images/icons/malfunction-indicator-error.svg`)
      else if(Number(this.payload?.malfunction_indicator_lamp_status) == 3) return require(`@/assets/images/icons/malfunction-indicator-unavailable.svg`)
      return require(`@/assets/images/icons/malfunction-indicator-off.svg`)
    },
    malfunctionLabel() {
      if(Number(this.payload?.malfunction_indicator_lamp_status) == 1) return 'On'
      else if(Number(this.payload?.malfunction_indicator_lamp_status) == 2) return 'Error'
      else if(Number(this.payload?.malfunction_indicator_lamp_status) == 3) return 'Unavailable'
      return 'Off'
    },
    canBusPayload() {
      return [
        [
          { 
            icon: 'vehicle-speed', 
            payloadKey:'wheel_based_vehicle_speed', 
            title: "Vehicle speed",
            value: this.payload.wheel_based_vehicle_speed ?? '-', 
          }, { 
            icon: 'fuel-level', 
            payloadKey:'fuel_level', 
            value: this.payload.fuel_level ?? '-', 
          }, {
            icon: this.coolantTempIcon,
            title: 'Coolant temperature',
            payloadKey:'engine_coolant_temperature', 
            value: this.payload.engine_coolant_temperature ?? '-', 
          },
        ], [
          { 
            icon: 'altitude', 
            payloadKey:'altitude', 
            value: this.payload.altitude ?? '-', 
          }, { 
            icon: 'magneto', 
            payloadKey:'-', 
            title: 'Heading',
            value: (this.payload.heading ? `${this.payload.heading}°, ` : '-') + (this.payload.heading_cardinal ? this.payload.heading_cardinal : '') 
          }, { 
            icon: 'gyro', 
            payloadKey:'elevation_info', 
            value: this.payload.elevation_info ?? '-', 
          },      
        ]
      ]
    },
  },
  watch: {
    payload: {
      deep: true,
      immediate: false,
      handler() {
        this.getDeviceAddress()
      }
    }
  },
  methods: {
    parsePayloadKey,
    async getDeviceAddress() {
      this.isLoading = true
      try {
        const { data } = await this.$api.getDeviceAddress(this.fleetDetail.organization_id, this.fleetDetail.id);
        this.address = data.plus_code?.compound_code
      } catch (error) {
        console.log(error);
      } finally {
        this.isLoading = false
      }
    },
  },
};
</script>

