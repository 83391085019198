<template>
  <div class="card-body ps-4 overflow-auto h-100">
    <!-- Fleet Info -->
    <DashboardFleetInfo
      :fleet-detail="fleetDetail"
      :filter="filterDate"
    />
    <div
      v-if="fleetDetail.is_activated"
      class="mt-3"
    >
      <b-row
        v-if="userRole <=1"
        class="row-padding mb-3"
      >
        <div class="custom-col">
          <div
            class="d-flex ms-2" 
            style="font-size: 14px;"
          >
            <span
              class="col-3"
              style="font-weight: 600;"
            >TX Power</span> 
            <span class="col-9">: {{ fleetDetail.tx_power_dbm ? `${fleetDetail.tx_power_dbm} dBm` : '-' }}  </span>
          </div>
        </div>
        <div class="custom-col">
          <div
            class="d-flex ms-2" 
            style="font-size: 14px;"
          >
            <span
              class="col-3"
              style="font-weight: 600;"
            >Last TX Power At</span> 
            <span class="col-9">: {{ fleetDetail.last_tx_power_at ? format(new Date(fleetDetail.last_tx_power_at), 'dd MMM yyyy HH:mm') : '-' }} </span>
          </div>
        </div>
      </b-row>
      <!-- Latest Payload -->
      <DashboardFleetLatestPayload 
        class="mb-3"
        :payload="latestPayload"
      />
      <!-- Trace Estimation -->
      <DashboardFleetTripOverview
        class="mb-3"
        :fleet-detail="fleetDetail"
        :filter="filterDate"
        :is-quester="isQuester"
      />
      <!-- Site Activity -->
      <DashboardFleetSiteActivity
        :fleet-detail="fleetDetail"
        :fleet-site-activity="fleetSiteActivity"
      />
    </div>
    <div
      v-else
      class="mt-1"
    >
      <!-- Device Not Activated -->
      <b-card-body class="text-center bg-soft-danger py-2"> 
        Device <b>{{ fleetDetail.name }}</b> is not activated. <br>
        Please contact the administrator for more information.
      </b-card-body>
    </div>
  </div>
</template>

<script>
import Echo from '@/services/web-socket';
import { mapGetters } from 'vuex';
import { format } from 'date-fns';
import DashboardFleetInfo from '@/components/dashboard/DashboardFleetInfo.vue';
import DashboardFleetLatestPayload from '@/components/dashboard/DashboardFleetLatestPayload.vue';
import DashboardFleetSiteActivity from '@/components/dashboard/DashboardFleetSiteActivity.vue';
import DashboardFleetTripOverview from '@/components/dashboard/DashboardFleetTripOverview.vue';


export default {
  components: {
    DashboardFleetInfo,
    DashboardFleetLatestPayload,
    DashboardFleetSiteActivity,
    DashboardFleetTripOverview,
  },
  props: {
    fleetDetail: {
      type: Object,
      default() {
        return {}
      } 
    },
    filterDate: {
      type: Object,
      default() {
        return {
          start_date: format(new Date(), 'yyyy-MM-dd')+ " 00:00",
          end_date: format(new Date(), 'yyyy-MM-dd') + " 23:59",
        }
      } 
    },
    fleetSiteActivity: {
      type: Array,
      default() {
        return []
      }
    },
  },
  data() {
    return {
      latestPayload: {}
    }
  },
  computed: {
    ...mapGetters('organization', ['userRole']),
    isQuester() {
      return this.latestPayload?.can_bus?.wheel_based_vehicle_speed != null
    }
  },
  watch: {
    fleetDetail: {
      deep: true,
      immediate: true, 
      handler(val) {
        this.latestPayload = val.last_location ?? {}
      }
    }
  },
  activated() {
    this.subscribePayload()
  },
  deactivated() {
    this.unsubscribePayload()
  },
  methods: {
    format,
    subscribePayload() {
      const deviceId = this.fleetDetail?.id
      if (deviceId != null) {
        Echo.subscribeChannel(`device.${deviceId}.location`)
        // listen new location payloads
        .listen('LocationReceived', (data) => {
          const newLocation = data.location
          this.latestPayload = newLocation
        });
      }
    },
    unsubscribePayload() {
      const deviceId = this.fleetDetail?.id
      if (deviceId != null) {
        Echo.leaveChannel(`device.${deviceId}.location`)
      }
    },
  },
};
</script>