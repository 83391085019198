<template>
  <div>
    <div
      ref="map"
      style="height: 100%"
    />
    <div class="d-none">
      <InfoWindowSite
        ref="info-window-site"
        :site-detail="selectedMarker"
      />
      <InfoWindowFleet
        ref="info-window-fleet"
        :fleet-detail="selectedMarker"
        @trace-fleet="selectFleet"
      />
    </div>
  </div>
</template>
<script>
import { toRaw } from 'vue';
import { capitalize, getSiteMarkerIcon, getCenterLocation } from '@/utils/helper';
import { differenceInHours } from 'date-fns';
import { MarkerClusterer } from "@googlemaps/markerclusterer";

import InfoWindowSite from '@/components/tooltip/InfoWindowSite.vue';
import InfoWindowFleet from '@/components/tooltip/InfoWindowFleet.vue';

export default {
  components: {
    InfoWindowSite,
    InfoWindowFleet,
  },
  props: {
    devices: {
      type: Array,
      default: () => []
    },
    sites: {
      type: Array,
      default: () => []
    },
    convoyData: {
      type: Array,
      default: () => []
    },
    devMode: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      renderedDevices: [],
      renderedConvoyPolyline: [],
      selectedMarker: {},
      firstInit: false,
      infoWindowConvoy: []
    };
  },
  computed: {
    deviceDataArr() {
      if(this.devices.length) {
        const dataArr = this.devices.map((item) => (
          {
            id: item.id,
            datetime: item.last_location?.created_at ?? '',
            vehicle_number: item.vehicle_number ?? '',
            driver_name: item.driver_name ?? '',
            latitude: Number(item.last_location?.location?.coordinates[1]),
            longitude: Number(item.last_location?.location?.coordinates[0]),
            organization_id: item.organization_id ?? '',
            payload: { ...item.last_location, ...item.last_location?.can_bus }
          }
        ))
        return dataArr
      } return []
    },
    siteDataArr() {
      if(this.sites.length) {
        const dataArr = this.sites.map((item) => (
          {
            id: item.id,
            name: item.name,
            address: item.address,
            latitude: Number(item.latitude),
            longitude: Number(item.longitude),
            radius: Number(item.max_distance),
            category: item.category
          }
        ))
        return dataArr
      } return []
    },
    convoyDataArr() {
      if(this.convoyData.length) {
        const dataArr = this.convoyData.map((item) => (
          {
            start: this.deviceDataArr.find(device => device.id == item.start),
            end: this.deviceDataArr.find(device => device.id == item.end),
            distance: item.distance,
          }
        ))
        return dataArr
      } return []
    },
    infoWindowContent() {
      const infoWindow = this.$refs['info-window-fleet']
      return infoWindow.$refs['content']
    },
    infoWindowContentSite() {
      const infoWindow = this.$refs['info-window-site']
      return infoWindow.$refs['content']
    },
  },
  watch: {
    deviceDataArr: {
      deep: true,
      immediate: false,
      handler() {
        this.drawDeviceMarkers()
        this.drawConvoyData()
      }
    },
    siteDataArr: {
      deep: true,
      immediate: false,
      handler() {
        this.initMap()
        this.drawSiteMarkers()
        this.drawDeviceMarkers()
      }
    },
    convoyDataArr: {
      deep: true,
      immediate: false,
      handler() {
        for (let i = 0; i < this.convoyDataArr.length; i++) {
          this.infoWindowConvoy[i].setHeaderContent(`${this.convoyDataArr[i].distance} m`)
        }
      }
    },
  },
  mounted() {
    this.initMap()
  },
  methods: {
    capitalize,
    async initMap() {
      window.map = new google.maps.Map(this.$refs['map'], {
        center: new google.maps.LatLng(-6.9447703, 110.4975125),
        zoom: 15,
        mapTypeId: google.maps.MapTypeId.ROADMAP,
        mapTypeControl: true,
      })
      
      // Initiate info window
      window.infoWindow = new google.maps.InfoWindow({
        content: '',
      });
      
      const trafficLayer = new google.maps.TrafficLayer();
      trafficLayer.setMap(map);

      // Marker Clusterer
      const markers = []
      window.markerCluster = new MarkerClusterer({map, markers});
      this.firstInit = true
    },
    drawDeviceMarkers() {
      // Clear Existing Marker
      this.renderedDevices.forEach(marker => toRaw(marker).setMap(null))
      this.renderedDevices = []

      // Gmaps bound
      const bounds = new google.maps.LatLngBounds(); 

      // Create gmaps marker instance and put in in an array
      for (let i = 0; i < this.deviceDataArr.length; i++) {
        let newMarker = new google.maps.Marker({
          position: new google.maps.LatLng(Number(this.deviceDataArr[i].latitude ?? 0), Number(this.deviceDataArr[i].longitude ?? 0)),
          map: window.map,
          icon: {
            url: this.getDeviceMarkerIcon(this.deviceDataArr[i]),
            labelOrigin: new google.maps.Point(15, -15),
          },
          label: {
            text: this.deviceDataArr[i].vehicle_number,
            className : 'fw-bold text-primary',
            fontSize: '15px',
            fontFamily: 'Inter, sans-serif',
          }
        });

        // Add event listener to marker on click
        newMarker.addListener("click", () => {
          // Update InfoWindow Content
          this.selectedMarker = this.deviceDataArr[i] ?? {}
          window.infoWindow.setContent(this.infoWindowContent)
          // Show InfoWindow
          window.infoWindow.open({
            anchor: newMarker,
            map: window.map,
          });
        })

        if (this.deviceDataArr[i].latitude && this.deviceDataArr[i].longitude) {
          bounds.extend(new google.maps.LatLng(Number(this.deviceDataArr[i].latitude), Number(this.deviceDataArr[i].longitude)));
        }
        this.renderedDevices.push(newMarker)
      }

      if (this.firstInit) {
        if (this.renderedDevices.length) {
          map.fitBounds(bounds);
        } else {
          map.setCenter(new google.maps.LatLng(-6.9447703, 110.4975125))
        }
        
        map.addListener('zoom_changed', () => {
          this.drawDeviceMarkers();
        })

        this.firstInit = false
      }
      this.drawCluster()
    },
    drawCluster() {
      if(window.markerCluster) {
        markerCluster.clearMarkers()
        markerCluster.addMarkers(this.renderedDevices)
      }
    },
    drawSiteMarkers() {
      if (this.siteDataArr.length) {
        for (let i = 0; i < this.siteDataArr.length; i++) {
          let newMarker = new google.maps.Marker({
            position: new google.maps.LatLng(Number(this.siteDataArr[i].latitude ?? 0), Number(this.siteDataArr[i].longitude ?? 0)),
            map: window.map,
            icon: {
              url: getSiteMarkerIcon(this.siteDataArr[i].category),
              origin: new google.maps.Point(0, 0),
              anchor: new google.maps.Point(13, 13),
              scaledSize: new google.maps.Size(25, 25)
            }
          });
          
          // Add event listener to marker on click
          newMarker.addListener("click", () => {
            // Update InfoWindow Content
            this.selectedMarker = this.siteDataArr[i] ?? {}
            window.infoWindow.setContent(this.infoWindowContentSite)
            // Show InfoWindow
            window.infoWindow.open({
              anchor: newMarker,
              map: window.map,
            });
          })
        }
      }
    },
    drawConvoyData() {
      // Remove existing polyline
      this.renderedConvoyPolyline.forEach((line) => toRaw(line).setMap(null))
      this.renderedConvoyPolyline = []

      // Initiate info window for convoy
      this.infoWindowConvoy.forEach(item => item.close())
      this.infoWindowConvoy = []
      for (let i = 0; i < 4; i++) {
        const infoWindow = new google.maps.InfoWindow({
          content: '',
        });
        this.infoWindowConvoy.push(toRaw(infoWindow))
      }

      for (let i = 0; i < this.convoyDataArr.length; i++) {
        const startLocation = this.convoyDataArr[i].start 
        const endLocation = this.convoyDataArr[i].end 
        let newLine = new google.maps.Polyline({
          path: [{ lat: startLocation.latitude ?? 0, lng: startLocation.longitude ?? 0 }, { lat: endLocation.latitude ?? 0, lng: endLocation.longitude ?? 0 }],
          geodesic: true,
          strokeColor: "#4B38B3",
          strokeOpacity: 1,
          strokeWeight: 4,
        });
        this.renderedConvoyPolyline.push(newLine)
        newLine.setMap(map)
        // Find Center of the line
        let center = getCenterLocation(startLocation, endLocation);
        // Fill info window content & display on the center of the line
        this.infoWindowConvoy[i].setHeaderContent(`${this.convoyDataArr[i].distance} m`)
        this.infoWindowConvoy[i].setPosition(new google.maps.LatLng(Number(center.latitude), Number(center.longitude)))
        this.infoWindowConvoy[i].open({
          map: window.map,
        });
        // Line event listener
        newLine.addListener("click", () => {
          this.infoWindowConvoy[i].open({
            map: window.map,
          });
        })
      }
    },
    getDeviceMarkerIcon(deviceData) {
      const now = new Date()
      if(deviceData.datetime) {
        const deviceDate = new Date(deviceData.datetime)
        if(differenceInHours(now, deviceDate) >= 1) {
          return require('@/assets/images/googlemaps/fleet/fleet-yellow.svg')
        }
      }
      return require('@/assets/images/googlemaps/fleet/fleet-purple.svg')
    },
    
    selectFleet(fleet) {
      this.$emit('select-fleet', fleet)
    }
  },
};
</script>
