<template>
  <div class="map-container overflow-auto">
    <PayloadMap
      :style="`${profile.is_superadmin ? `height: 80%` : `height: 100%`};`"
      class="px-0"
      :device-detail="selectedFleet"
      :sites="expeditionSites"
      :payloads="payloads"
      :payload-meta="payloadMeta"
      :enable-route="enableRoute"
    />
    <div v-if="profile.is_superadmin">
      <PayloadTable
        class="p-3"
        :payload-keys="payloadKeys"
        :payloads="processedPayloads"
        :is-loading="isLoading"
      />
    </div>
  </div>
</template>

<script>
import Echo from '@/services/web-socket';
import PayloadMap from "@/components/PayloadMap";
import PayloadTable from '@/components/PayloadTable.vue'; 

import { format, isWithinInterval } from 'date-fns';
import { payloadOrder } from '@/utils/constant';

export default {
  components: {
    PayloadMap,
    PayloadTable
  },
  props: {
    selectedFleet: {
      type: Object,
      default() {
        return {}
      }
    },
    filterDate: {
      type: Object,
      default() {
        return {
          start_date: format(new Date(), 'yyyy-MM-dd')+ " 00:00",
          end_date: format(new Date(), 'yyyy-MM-dd') + " 23:59",
        }
      }
    },
    enableRoute: {
      type: Boolean,
      default: false,
    },
    loadMoreCounter: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      isLoading: false,
      payloads: [],
      payloadMeta: {},
      fleetSiteLog: []
    };
  },
  computed: {
    profile() {
      return this.$store.state.auth.profile;
    },
    payloadKeys() {
      const hiddenPayloads = ['_id', 'coordinates', 'device_id', 'id', 'location', 'created_at', 'altitude', 'updated_at', 'can_bus']
      let payloadKeys = [
        { key: 'created_at', label: 'Collected Date' },
        { key: 'latitude', label: 'Latitude' },
        { key: 'longitude', label: 'Longitude' },
        { key: 'altitude', label: 'Altitude' },
      ]
      let extraPayloadKeys = []
      if (this.payloads[0]) {
        const extra = Object.keys(this.payloads[0]).filter(payloadKey => !hiddenPayloads.includes(payloadKey))
        extraPayloadKeys = extraPayloadKeys.concat(extra)
      } if (this.payloads[0]?.can_bus) {
        const canbus = Object.keys(this.payloads[0].can_bus).filter(payloadKey => !hiddenPayloads.includes(payloadKey))
        extraPayloadKeys = extraPayloadKeys.concat(canbus)
      }

      const sortedPayload = payloadOrder.filter(item => extraPayloadKeys.includes(item))
      const remainingPayload = extraPayloadKeys.filter(item => !sortedPayload.includes(item))
      extraPayloadKeys = sortedPayload.concat(remainingPayload).map(key => ({ key, label: key.toString().replace('_', ' ') }));
      return payloadKeys.concat(extraPayloadKeys)
    },
    processedPayloads() {
      return this.payloads.map(payload => (
          { ...payload, ...payload.can_bus ?? {} }
        )
      )
    },
    expeditionSites() {
      const allExpeditionSites = this.fleetSiteLog.map(item => item.place)
      return [
        ...new Map(allExpeditionSites.map(obj => [`${obj.id}`, obj]))
        .values()
      ];
    }
  },
  watch: {
    filterDate: {
      immediate: false,
      deep: true,
      handler() {
        this.traceFleet()
      }
    },
    loadMoreCounter: {
      handler() {
        this.loadMore()
      }
    },
  },
  activated() {
    this.subscribePayload()
  },
  deactivated() {
    this.unsubscribePayload()
  },
  methods: {
    async traceFleet(page = 1) {
      this.isLoading = true
      try {
        const { data } = await this.$api.getDeviceLocation(this.selectedFleet.id, {
          start_date: new Date(this.filterDate.start_date).toISOString(), 
          end_date: new Date(this.filterDate.end_date).toISOString(),
          sources: ['GEO_RESOLVER_GNSS'],
          per_page: 24,
          page 
        });

        if (page === 1) {
          this.getFleetSiteLog()
          this.payloads = [];
          this.payloadMeta = {};
          this.firstPayload = data.data[0] ?? {}
        }
        this.payloads = this.payloads.concat(data.data)
        this.payloadMeta = data.meta;
        this.$emit('update-meta', this.payloadMeta)
      } catch (error) {
        console.log(error);
      } finally {
        this.isLoading = false
      }
    },

    async getFleetSiteLog() {
      // Get sites history based on expedition timestamp
      const { data } = await this.$api.getDevicePlaceLog(this.selectedFleet.organization_id, this.selectedFleet.id, {
        method: 'geofence',
        start_date: new Date(this.filterDate.start_date).toISOString(),
        end_date: new Date(this.filterDate.end_date).toISOString(),
        per_page: 100,
      });
      this.fleetSiteLog = data.data;
      this.$emit('update-fleet-site-log', this.fleetSiteLog)
    },

    async loadMore() {
      await this.traceFleet(this.payloadMeta.current_page + 1);
    },

    subscribePayload() {
      const deviceId = this.selectedFleet?.id
      if (deviceId != null) {
        Echo.subscribeChannel(`device.${deviceId}.location`)
        // listen new location payloads
        .listen('LocationReceived', (data) => {
          const newPayload = { ...data.location }
          // push only if payload is within filterDate
          const withinInterval = isWithinInterval(new Date(newPayload.created_at), {
            start: new Date(this.filterDate.start_date),
            end: new Date(this.filterDate.end_date)
          })
          if (withinInterval) {
            this.payloads = [newPayload].concat(this.payloads)
          }
        });
      }
    },
    unsubscribePayload() {
      const deviceId = this.selectedFleet?.id
      if (deviceId != null) {
        Echo.leaveChannel(`device.${deviceId}.location`)
      }
    },
  },
};
</script>