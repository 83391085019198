<template>
  <div>
    <b-button
      class="w-100 hover-invert"
      variant="outline-primary"
      data-bs-toggle="modal"
      href="#device-info-modal"
    >
      <img
        src="@/assets/images/icons/fleet-vin.svg"
        alt=""
        title="Vehicle Identification Number"
        style="height: 22px;"
        class="my-auto"
      >
    </b-button>
    <b-modal
      id="device-info-modal"
      size="md"
      title="Fleet Identity"
      centered
      hide-footer
    >
      <div
        class="mb-3"
        style="border: 1px solid #CED4DA"
      >
        <div
          class="fs-15 bg-light p-2"
          style="border-bottom: 1px solid #CED4DA"
        >
          Basic Information
        </div>
        <div class="p-2">
          <FleetModelData 
            :value="fleetDetail.vehicle_number ?? '-'"
          >
            <template #label>
              <b>Vehicle Number</b> (No.Polisi)
            </template>
          </FleetModelData>
          <FleetModelData 
            label="Driver Name"
            :value="fleetDetail.driver_name ?? '-'"
          />
          <FleetModelData 
            :value="fleetDetail.engine_number ?? '-'"
          >
            <template #label>
              <b>Engine Number</b> (No. Seri Mesin)
            </template>
          </FleetModelData>
        </div>
      </div>
      <div
        class="mb-3"
        style="border: 1px solid #CED4DA"
      >
        <div
          class="d-flex justify-content-between bg-light p-2"
          style="border-bottom: 1px solid #CED4DA"
        >
          <span class="fs-15">
            Device Activation
          </span>
          <div
            class="d-flex px-2 bg-white"
            style="white-space: nowrap; border: 1px solid; border-radius: 20px;"
            :class="fleetDetail.is_activated ? 'border-success' : 'border-danger'"
          >
            <i
              class="bx bxs-circle my-auto me-2"
              :class="fleetDetail.is_activated ? 'text-success' : 'text-danger'"
            />
            {{ fleetDetail.is_activated == 1 ? 'Activated' : fleetDetail.is_activated === null ? 'Not Activated' : 'Activation Failed' }}
          </div>
        </div>
        <div class="p-2">
          <FleetModelData 
            :value="fleetDetail.vin ?? '-'"
          >
            <template #label>
              <b>Vehicle Identification Number</b> (No. Seri Rangka)
            </template>
          </FleetModelData>
          <FleetModelData 
            label="Device ID"
            :value="fleetDetail.device_id ?? '-'"
          />
          <FleetModelData 
            label="Device EUI"
            :value="fleetDetail.device_eui ?? '-'"
          />
        </div>
      </div>
      <div v-if="!fleetDetail.is_activated">
        <b-card-body
          class="py-2 text-center"
          style="border: 1px solid #F06548;"
        >
          Device is not activated yet! <br>
          Please contact administrator for more information.
        </b-card-body>
      </div>
    </b-modal>
  </div>
</template>

<script>
import FleetModelData from '@/components/fleet/FleetModelData.vue';

export default {
  components: {
    FleetModelData,
  },
  props: {
    fleetDetail: {
      type: Object,
      default() {
        return {
          name: '',
          device_id: '',
          device_eui: ''
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.hover-invert:hover > img {
  filter: invert(1);
}
</style>
