<template>
  <div>
    <!-- Datetime -->
    <b-row class="row-padding mb-1">
      <div class="custom-col">
        <div
          class="ms-2" 
          style="font-size: 16px;"
        >
          <span style="font-weight: 600;">Latest Position :</span> {{ datetime }} 
        </div>
      </div>
    </b-row>
    <b-row class="row-padding mb-1">
      <div class="custom-col">
        <!-- Address -->
        <div class="d-flex ms-2 py-1">
          <img
            :src="require(`@/assets/images/icons/address.svg`)"
            title="Location"
            style="height: 22px;"
            alt=""
            class="my-auto"
          >
          <div class="my-auto ms-2">
            {{ fleetAddress }}
          </div>
        </div>
        <!-- Not Quester Payload -->
        <div v-if="trackerType != 'quester'">
          <div
            v-for="(item, index) in nonQuesterPayloads"
            :key="index"
            class="d-flex ms-2 py-1"
          >
            <img
              :src="require(`@/assets/images/icons/${item.icon}.svg`)"
              :title="parsePayloadKey(item.payloadKey).name"
              style="height: 22px;"
              alt=""
              class="my-auto"
            >
            <div class="my-auto ms-2">
              {{ item.value }} {{ parsePayloadKey(item.payloadKey).unit }}
            </div>
          </div>
        </div>
      </div>
    </b-row>
    <b-row class="row-padding mb-1 gap-1">
      <b-col class="d-flex flex-column justify-content-between">
        <!-- Quester Payloads -->
        <b-row
          v-if="trackerType == 'quester'"
          class="gap-1 mb-1 h-100"
        >
          <b-col 
            v-for="(data, idx) in questerPayloads"
            :key="idx"
            class="custom-col d-flex flex-column justify-content-between"
          >
            <div
              v-for="(item, index) in data"
              :key="index"
              class="d-flex ms-2 pb-1"
            >
              <img
                :src="require(`@/assets/images/icons/${item.icon}.svg`)"
                :title="item.title ? item.title : parsePayloadKey(item.payloadKey).name"
                style="height: 22px;"
                alt=""
                class="my-auto"
              >
              <span class="ms-2 my-auto">
                {{ item.value }} {{ parsePayloadKey(item.payloadKey).unit }}
              </span>
            </div>
          </b-col>
        </b-row>
        <!-- Indicators -->
        <b-row
          v-if="trackerType == 'quester'"
          class="gap-1"
        >
          <b-col
            v-for="(indicator, idx) in indicatorList"
            :key="idx"
            class="d-flex custom-col"
          >
            <img
              :src="require(`@/assets/images/icons/${indicator.icon}.svg`)"
              :title="indicator.title"
              alt=""
              style="height: 22px;"
              class="my-auto mx-auto"
              :class="indicator.class"
            >
          </b-col>
        </b-row>
      </b-col>
      <b-col
        v-if="trackerType == 'quester'"
        class="custom-col"
        md="2"
      > 
        <BrakePressureGauge
          :primary-value="processedPayload.brake_primary_pressure ?? 0"
          :secondary-value="processedPayload.brake_secondary_pressure ?? 0"
        />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { formatDateTime, parsePayloadKey } from '@/utils/helper';
import BrakePressureGauge from '@/components/dashboard/BrakePressureGauge.vue';

export default {
  components: {
    BrakePressureGauge,
  },
  props: {
    payload: {
      type: Object,
      default() {
        return {}
      } 
    },
  },
  data() {
    return {
      fleetAddress: '',
    }
  },
  computed: {
    trackerType() {
      if(this.processedPayload?.wheel_based_vehicle_speed != null) {
        return 'quester'
      } else if (this.processedPayload?.ignition != null) {
        return 'smf'
      } return 'teseo'
    },
    processedPayload() {
      const canBus = this.payload?.can_bus ?? {}
      return { ...this.payload, ...canBus }
    },
    datetime() {
      return this.payload?.created_at ? formatDateTime(this.payload?.created_at, true) : ''
    },
    location() {
      if(this.payload.location?.coordinates) {
        const latitude = this.payload.location?.coordinates[1]
        const longitude = this.payload.location?.coordinates[0]
        return `${latitude}, ${longitude}`
      } return ''
    },
    indicatorList() {
      return [
          { title: 'Ignition', icon: this.processedPayload.ignition ? 'ignition-on' : 'ignition-off', class: '' },
          { title: 'Parking Brake', icon: this.processedPayload.parking_brake ? 'parking-brake-on' : 'parking-brake-off', class: '' },
          { title: 'Malfunction Indicator', icon: 'malfunction-indicator-off', class: this.malfunctionStatus },
          { title: 'Protect Indicator', icon: 'protect-lamp', class: this.protectLampStatus },
          { title: 'Amber Warning', icon: 'amber-warning', class: this.amberWarningStatus },
          { title: 'Red Stop Lamp', icon: this.redStopIcon, class: '' },
        ]
    },
    nonQuesterPayloads() {
      if(this.trackerType == 'teseo') {
        return [
          { icon: 'location',  payloadKey:'-', value: this.location },
          { icon: 'altitude',  payloadKey:'altitude', value: this.processedPayload.altitude ?? '-' },
        ]
      } else if(this.trackerType == 'smf') {
        return [
          { icon: 'location',  payloadKey:'-', value: this.location },
          { icon: 'altitude',  payloadKey:'altitude', value: this.processedPayload.altitude ?? '-' },
          { icon: 'vehicle-speed',  payloadKey:'gps_vehicle_speed', value: this.processedPayload.gps_vehicle_speed ?? '-' },
          { icon: this.processedPayload.ignition ? 'ignition-on' : 'ignition-off', payloadKey: 'ignition', value: this.processedPayload.ignition ? 'Ignition On' : 'Ignition Off' }
        ]
      } return []
    },
    questerPayloads() {
      return [
        [
          { 
            icon: 'vehicle-speed', 
            payloadKey:'wheel_based_vehicle_speed', 
            title: "Vehicle speed",
            value: this.processedPayload.wheel_based_vehicle_speed ?? '-', 
          }, { 
            icon: 'engine-speed', 
            payloadKey:'engine_speed', 
            value: this.processedPayload.engine_speed ?? '-', 
          }, { 
            icon: 'fuel-level', 
            payloadKey:'fuel_level', 
            value: this.processedPayload.fuel_level ?? '-', 
          }, {
            icon: 'avg-fuel-economy', 
            payloadKey:'average_fuel_economy', 
            value: this.processedPayload.average_fuel_economy ?? '-', 
          }, {
            icon: 'coolant-temp',
            title: 'Coolant temperature',
            payloadKey:'engine_coolant_temperature', 
            value: this.processedPayload.engine_coolant_temperature ?? '-', 
          },    
        ], [
          { 
            icon: 'location', 
            payloadKey:'-', 
            value: this.location
          }, { 
            icon: 'altitude', 
            payloadKey:'altitude', 
            value: this.processedPayload.altitude ?? '-', 
          }, { 
            icon: 'magneto', 
            payloadKey:'-', 
            title: 'Heading',
            value: (this.processedPayload.heading ? `${this.processedPayload.heading}°, ` : '-') + (this.processedPayload.heading_cardinal ? this.processedPayload.heading_cardinal : '') 
          }, { 
            icon: 'gyro', 
            payloadKey:'elevation_info', 
            value: this.processedPayload.elevation_info ?? '-', 
          }, {
            icon: 'coolant-level',
            title: 'Coolant level',
            payloadKey:'coolant_level', 
            value: this.processedPayload.coolant_level ?? '-', 
          },   
        ]
      ]
    },
    malfunctionStatus() {
      if(this.processedPayload?.malfunction_indicator_lamp_status == 1) return 'indicator-on'
      if(this.processedPayload?.malfunction_indicator_lamp_status == 2) return 'indicator-error'
      if(this.processedPayload?.malfunction_indicator_lamp_status == 3) return 'indicator-unavailable'
      return ''
    },
    protectLampStatus() {
      if(this.processedPayload?.protect_lamp_status == 1) return 'indicator-on'
      if(this.processedPayload?.protect_lamp_status == 2) return 'indicator-error'
      if(this.processedPayload?.protect_lamp_status == 3) return 'indicator-unavailable'
      return ''
    },
    amberWarningStatus() {
      if(this.processedPayload?.engine_amber_warning_lamp_status == 1) return 'indicator-on'
      if(this.processedPayload?.engine_amber_warning_lamp_status == 2) return 'indicator-error'
      if(this.processedPayload?.engine_amber_warning_lamp_status == 3) return 'indicator-unavailable'
      return ''
    },
    redStopIcon() {
      if(this.processedPayload?.engine_red_stop_lamp_status == 1) return 'red-stop-lamp-on'
      if(this.processedPayload?.engine_red_stop_lamp_status == 2) return 'red-stop-lamp-error'
      if(this.processedPayload?.engine_red_stop_lamp_status == 3) return 'red-stop-lamp-unavailable'
      return 'red-stop-lamp-off'
    },
  },
  watch: {
    payload: {
      deep: true,
      immediate: true,
      handler() {
        this.getDeviceAddress()
      }
    }
  },
  methods: {
    parsePayloadKey,
    async getDeviceAddress() {
      const organizationId = this.$store.state.organization.selectedOrganization
      try {
        const { data } = await this.$api.getDeviceAddress(organizationId, this.payload.device_id);
        this.fleetAddress = data.plus_code?.compound_code
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>

<style>
.custom-col {
  padding: 0.25em 0;
  background-color: #F3F6F9;
  font-weight: 500;
}
.row-padding {
  padding: 0 1em;
}
.indicator-on {
  filter: invert(51%) sepia(94%) saturate(2431%) hue-rotate(1deg) brightness(103%) contrast(104%);
}
.indicator-error {
  filter: invert(42%) sepia(97%) saturate(7498%) hue-rotate(308deg) brightness(103%) contrast(120%);
}
.indicator-unavailable {
  filter: invert(21%) sepia(96%) saturate(7324%) hue-rotate(265deg) brightness(89%) contrast(132%);
}
</style>