<template>
  <div class="d-flex justify-content-between w-100">
    <div
      v-if="mode == 'dashboard'"
      class="d-flex my-auto justify-content-between w-100"
    >
      <div class="my-auto">
        <h4 class="my-auto">
          TRACK FLEET
        </h4>
      </div>
      <div
        v-if="userRole <= 1"
        class="me-2"
      >
        <b-button
          variant="primary"
          class="ms-auto"
          data-bs-toggle="modal"
          href="#device-form-modal"
        >
          Register Fleet
        </b-button>
        <FleetFormModal
          :fleet-detail="{}"
          :fleet-models="fleetModels"
          @update-data="$emit('update-data')"
        />
      </div>
    </div>
    <!-- Trace Fleet  -->
    <div
      v-else
      class="d-flex justify-content-between pe-2 w-100 my-auto"
    >
      <div
        class="d-flex" 
        style="max-width: 65%;"
      >
        <b-button
          variant="primary"
          @click="$emit('go-back')"
        >
          <i class="ri-arrow-left-line my-auto fw-bold" />
        </b-button>
        <h4
          class="ms-3 my-auto"
          style="white-space: nowrap; text-overflow: ellipsis; max-height: 18px; overflow: hidden;"
        >
          {{ fleetDetail.vehicle_number }} {{ fleetDetail.driver_name ? ` - ` : `` }} {{ fleetDetail.driver_name }}
        </h4>
      </div>
      <div class="flex-shrink-0">
        <b-button
          v-if="userRole <= 1"
          variant="outline-info"
          class="ms-2"
          data-bs-toggle="modal"
          href="#device-form-modal"
        >
          <i class="ri-edit-2-line" />
        </b-button>
        <b-button
          v-if="userRole <= 0"
          class="ms-2"
          variant="outline-danger"
          data-bs-toggle="modal"
          href="#delete-modal"
        >
          <i class="ri-delete-bin-line" />
        </b-button>
      </div>
    </div>
    <FleetFormModal
      v-if="userRole <= 1"
      :fleet-detail="fleetDetail"
      :fleet-models="fleetModels"
      @update-data="$emit('update-data')"
    />
    <DeleteModal 
      v-if="userRole <= 0"
      entity="Fleet"
      :name="fleetDetail.vehicle_number ?? ''"
      @delete-data="doDelete"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import DeleteModal from '@/components/DeleteModal.vue';
import FleetFormModal from '@/components/fleet/FleetFormModal.vue';

import { useToast } from 'vue-toastification';
import ToastDefault from '@/components/ToastDefault.vue';

export default {
  components: {
    DeleteModal, 
    FleetFormModal
  },
  props: {
    mode: {
      type: String,
      default: 'dashboard' 
    },
    fleetDetail: {
      type: Object,
      default() {
        return {}
      } 
    },
  },
  setup () {
    const toast = useToast();
    return {
      toast,
    }
  },
  data() {
    return {
      fleetModels: []
    }
  },
  computed: {
    ...mapGetters('organization', ['userRole']),
    selectedOrganization() {
      return this.$store.state.organization.selectedOrganization;
    },
  },
  watch: {
    selectedOrganization(val) {
      if(val){
        this.getFleetModels()
      }
    }
  },
  methods: {
    async doDelete() {
      try {
        await this.$api.deleteOrganizationDevice(this.fleetDetail.organization_id, this.fleetDetail.id);           
        const toastContent = {
          component: ToastDefault,
          props: {
            variant: 'secondary',
            text: 'Deleted successfully',
            icon: 'ri-check-double-line',
          },
        };
        this.toast(toastContent);
        this.$emit('go-back', true)
      } catch (error) {
        console.log(error);

        const toastContent = {
          component: ToastDefault,
          props: {
            variant: 'danger',
            text: 'Process Failed',
          },
        };
        this.toast(toastContent);
      }
    },
    async getFleetModels() {
      try {
        const { data } = await this.$api.getOrganizationFleetModels(this.selectedOrganization);
        this.fleetModels = data.data;
      } catch (error) {
        console.log(error);  
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>
