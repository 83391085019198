<template>
  <div class="mb-1">
    <!-- Brand Series Type -->
    <b-row class="mb-1">
      <b-col
        lg="10"
        class="pe-lg-1 mb-1 mb-lg-0"
      >
        <div class="d-flex justify-content-between custom-col h-100 py-1">
          <div class="d-flex my-auto ms-2">
            <img
              v-if="fleetDetail.brand"
              :src="getFleetBrandIcon(fleetDetail.brand)"
              alt=""
              style="max-height: 20px; max-width: 28px;"
              class="my-auto mx-auto"
            >
            <span class="ms-2 my-auto fw-bold">
              {{ fleetDetail.brand }}
            </span>
            <span class="ms-2 my-auto">
              {{ fleetDetail.serie }}
            </span>
          </div>
          <div
            v-if="fleetDetail.fleet_type"
            class="d-flex"
          >
            <span
              class="my-auto text-capitalize px-2 flex-shrink-0 bg-white py-1 me-2"
              style="border: 1px solid; border-color: #909294; border-radius: 4px;"
            >
              {{ fleetType }}
            </span>
          </div>
        </div>
      </b-col>
      <b-col
        lg="2"
        class="ps-lg-0"
      >
        <FleetIdentityModal :fleet-detail="fleetDetail" />
      </b-col>
    </b-row>
    <!-- Fleet Information -->
    <b-row class="mb-1">
      <b-col
        lg="10"
        class="pe-lg-1"
      >
        <div class="d-lg-flex h-100 gap-1">
          <b-col class="bg-light h-auto py-1 mb-1 mb-lg-0">
            <div
              v-for="(item, index) in fleetInfo[0]"
              :key="index"
              class="d-flex"
            >
              <div
                v-if="item"
                class="mb-1"
              >
                <i
                  class="bx bxs-circle my-auto px-2"
                  style="color: #8C8C8C; font-size: 11px;"
                /> 
                <span class="my-auto fw-bold">{{ item.label }} :</span>
                <span class="my-auto ps-2 text-capitalize">{{ item.value }}</span>
                <span class="my-auto ps-2">{{ item.unit }}</span>
              </div>
            </div>
          </b-col>
          <b-col class="custom-col h-auto py-1 mb-1 mb-lg-0">
            <div
              v-for="(item, index) in fleetInfo[1]"
              :key="index"
              class="d-flex"
            >
              <div
                v-if="item"
                class="mb-1"
              >
                <i
                  class="bx bxs-circle my-auto px-2"
                  style="color: #8C8C8C; font-size: 11px;"
                /> 
                <span class="my-auto fw-bold">{{ item.label }} :</span>
                <span class="my-auto ps-2 text-capitalize">{{ item.value }}</span>
                <span class="my-auto ps-2">{{ item.unit }}</span>
              </div>
            </div>
          </b-col>
        </div>
      </b-col>
      <b-col
        lg="2"
        class="ps-lg-0"
      >
        <div
          class="d-flex py-1 h-100"
          :class="fleetDetail.is_activated ? 'bg-light' : 'bg-soft-danger'"
        >
          <div class="d-flex flex-column m-auto">
            <img
              :src="fleetDetail.is_activated ? require('@/assets/images/icons/device-status-activated.svg') : require('@/assets/images/icons/device-status-not-activated.svg')"
              alt=""
              :title="fleetDetail.is_activated == 1 ? 'Activated' : fleetDetail.is_activated === null ? 'Not Activated' : 'Activation Failed'"
              style="height: 32px;"
              class="my-auto mx-auto"
            >
            <div class="text-center">
              {{ fleetDetail.is_activated == 1 ? 'Activated' : fleetDetail.is_activated === null ? 'Not Activated' : 'Activation Failed' }}
            </div>
          </div>
        </div>
      </b-col>
    </b-row>

    <!-- Quester Odometer -->
    <b-row
      v-if="trackerType == 'quester'" 
      class="mb-1"
    >
      <b-col
        lg="10"
        class="pe-lg-1"
      >
        <div class="d-lg-flex h-100 gap-1">
          <b-col class="d-flex bg-light py-1 mb-1 mb-lg-0">
            <img
              src="@/assets/images/icons/engine-hour.svg"
              alt=""
              title="Engine Hours Operation"
              style="height: 24px;"
              class="my-auto ms-2"
            >
            <div class="my-auto ms-2">
              {{ engineHour }} h
            </div>
          </b-col>
          <b-col class="d-flex bg-light py-1 mb-1 mb-lg-0">
            <img
              src="@/assets/images/icons/odometer.svg"
              alt=""
              title="Odometer"
              style="height: 24px;"
              class="my-auto ms-2"
            >
            <div class="my-auto ms-2">
              {{ odometer }} km
            </div>
          </b-col>
        </div>
      </b-col>
      <b-col
        lg="2"
        class="ps-lg-0"
      >
        <div class="d-flex py-1 h-100 bg-light">
          <div class="d-flex flex-column m-auto">
            <img
              src="@/assets/images/icons/maintenance.svg"
              alt=""
              title="Maintenance"
              style="height: 24px;"
              class="my-auto mx-auto"
            >
          </div>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import FleetIdentityModal from '@/components/dashboard/FleetIdentityModal.vue';
import { fleetTypeOptions } from '@/utils/constant';
import { getFleetBrandIcon, getFleetClassificationLabel } from '@/utils/helper';
export default {
  components: {
    FleetIdentityModal,
  },
  props: {
    fleetDetail: {
      type: Object,
      default() {
        return {}
      }
    },
  },
  computed: {
    trackerType() {
      if(this.fleetDetail?.last_location?.can_bus?.wheel_based_vehicle_speed != null) {
        return 'quester'
      } else if (this.fleetDetail?.last_location?.can_bus?.ignition != null) {
        return 'smf'
      } return 'teseo'
    },
    odometer() {
      return this.fleetDetail?.last_location?.can_bus?.total_vehicle_distance ?? '-'
    },
    engineHour() {
      return this.fleetDetail?.last_location?.can_bus?.engine_total_hour_operation ?? '-'
    },
    fleetInfo() {
      return [
        [
          { label: 'Payload Capacity', value: this.fleetDetail.payload_capacity, unit: 'kg' },
          { label: 'Classification', value: getFleetClassificationLabel(this.fleetDetail.classification), unit: '' },
          { label: 'Cargo Type', value: this.fleetDetail.cargo_type, unit: '' }
        ], [
          { label: 'Engine Displacement', value: this.fleetDetail.engine_displacement, unit: 'cc' },
          { label: 'Fuel Capacity', value: this.fleetDetail.fuel_capacity, unit: 'L' },,
          { label: 'Fuel Type', value: this.fleetDetail.fuel_type, unit: '' }
        ]
      ]
    },
    fleetType() {
      const type = fleetTypeOptions.find(item => item.value == this.fleetDetail.fleet_type) 
      return type.label ?? '-'
    }
  },
  methods: {
    getFleetBrandIcon
  },
};
</script>