<template>
  <div ref="content">
    <h5>
      <span class="fw-bold">{{ datetime }}</span> {{ utcOffset }}
    </h5>
    <div>
      <b-col class="custom-col mb-1">
        <div class="d-flex pb-1">
          <img
            src="@/assets/images/icons/location.svg"
            alt=""
            title="Location"
            style="height: 22px;"
            class="my-auto ms-2"
          >
          <span class="ms-2 my-auto">
            {{ location }}
          </span>
        </div>
        <!-- Not Quester Altitude Payload -->
        <div
          v-if="trackerType != 'quester'"
          class="d-flex pb-1"
        >
          <img
            src="@/assets/images/icons/altitude.svg"
            alt=""
            class="my-auto ms-2"
            style="height: 22px;"
          >
          <span class="ms-2 my-auto">
            {{ processedPayload.altitude }} {{ parsePayloadKey('altitude').unit }}
          </span>
        </div>
        <!-- SMF Vehicle Speed -->
        <div
          v-if="trackerType == 'smf'"
          class="d-flex pb-1"
        >
          <img
            src="@/assets/images/icons/vehicle-speed.svg"
            alt=""
            class="my-auto ms-2"
            style="height: 22px;"
          >
          <span class="ms-2 my-auto">
            {{ processedPayload.gps_vehicle_speed }} {{ parsePayloadKey('gps_vehicle_speed').unit }}
          </span>
        </div>
        <!-- SMF Ignition -->
        <div
          v-if="trackerType == 'smf'"
          class="d-flex pb-1"
        >
          <img
            :src="processedPayload.ignition ? `${require(`@/assets/images/icons/ignition-on.svg`)}` : `${require(`@/assets/images/icons/ignition-off.svg`)}`"
            title="Ignition"
            alt=""
            class="my-auto ms-2"
          >
          <span class="my-auto ms-2">
            {{ processedPayload.ignition ? 'Ignition On' : 'Ignition Off' }}
          </span>
        </div>
      </b-col>
      <!-- Quester Payload -->
      <div
        v-if="trackerType == 'quester'"
        class="d-flex mb-1 gap-1"
      >
        <b-col 
          v-for="(data, idx) in payloadData"
          :key="idx"
          class="custom-col"
        >
          <div
            v-for="(item, index) in data"
            :key="index"
            class="d-flex ms-2 pb-1"
          >
            <img
              :src="require(`@/assets/images/icons/${item.icon}.svg`)"
              :title="item.title ? item.title : parsePayloadKey(item.payloadKey).name"
              style="height: 22px;"
              alt=""
              class="my-auto"
            >
            <span class="ms-2 my-auto">
              {{ item.value }} {{ parsePayloadKey(item.payloadKey).unit }}
            </span>
          </div>
        </b-col>
      </div>
      <!-- Quester Indicators -->
      <div
        v-if="trackerType == 'quester'"
        class="d-flex mb-1 gap-1"
      >
        <b-col class="d-flex pb-1 custom-col">
          <img
            :src="processedPayload.ignition ? `${require(`@/assets/images/icons/ignition-on.svg`)}` : `${require(`@/assets/images/icons/ignition-off.svg`)}`"
            title="Ignition"
            alt=""
            class="my-auto mx-auto"
          >
        </b-col>
        <b-col class="d-flex pb-1 custom-col">
          <img
            :src="processedPayload.parking_brake ? `${require(`@/assets/images/icons/parking-brake-on.svg`)}` : `${require(`@/assets/images/icons/parking-brake-off.svg`)}`"
            title="Parking Brake"
            alt=""
            class="my-auto mx-auto"
          >
        </b-col>
        <b-col class="d-flex pb-1 custom-col">
          <img
            :src="require(`@/assets/images/icons/${coolantTempIcon}.svg`)"
            title="Coolant Temperature"
            alt=""
            class="my-auto mx-auto"
          >
        </b-col>
        <b-col class="d-flex pb-1 custom-col">
          <img
            :src="malfunctionIcon"
            :title="malfunctionLabel"
            alt=""
            class="my-auto mx-auto"
          >
        </b-col>
      </div>
    </div>
  </div>  
</template>

<script>
import { utcOffset } from '@/utils/constant';
import { formatDateTime, parsePayloadKey } from '@/utils/helper';

export default {
  props: {
    deviceDetail: {
      type: Object,
      default() {
        return {}
      }
    },
    payload: {
      type: Object,
      default() {
        return {}
      }
    },
    devMode: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      utcOffset,
    }
  },
  computed: {
    trackerType() {
      if(this.processedPayload?.wheel_based_vehicle_speed != null) {
        return 'quester'
      } else if (this.processedPayload?.ignition != null) {
        return 'smf'
      } return 'teseo'
    },

    processedPayload() {
      const canBus = this.payload.can_bus ?? {}
      return { ...this.payload, ...canBus }
    },

    datetime() {
      return this.payload?.created_at ? formatDateTime(this.payload?.created_at) : ''
    },
    location() {
      if(this.payload.coordinates) {
        const latitude = this.payload.coordinates[1]
        const longitude = this.payload.coordinates[0]
        return `${latitude}, ${longitude}`
      } else if(this.payload.location?.coordinates) {
        const latitude = this.payload.location?.coordinates[1]
        const longitude = this.payload.location?.coordinates[0]
        return `${latitude}, ${longitude}`
      } 
      return ''
    },

    payloadData() {
      return [
        [
          { 
            icon: 'vehicle-speed', 
            payloadKey:'wheel_based_vehicle_speed', 
            title: "Vehicle speed",
            value: this.processedPayload.wheel_based_vehicle_speed ?? '-', 
          }, { 
            icon: 'engine-speed', 
            payloadKey:'engine_speed', 
            value: this.processedPayload.engine_speed ?? '-', 
          }, { 
            icon: 'fuel-level', 
            payloadKey:'fuel_level', 
            value: this.processedPayload.fuel_level ?? '-', 
          }, {
            icon: 'avg-fuel-economy', 
            payloadKey:'average_fuel_economy', 
            value: this.processedPayload.average_fuel_economy ?? '-', 
          },
        ], [
          { 
            icon: 'altitude', 
            payloadKey:'altitude', 
            value: this.processedPayload.altitude ?? '-', 
          }, { 
            icon: 'magneto', 
            payloadKey:'-', 
            title: 'Heading',
            value: (this.processedPayload.heading ? `${this.processedPayload.heading}°, ` : '-') + (this.processedPayload.heading_cardinal ? this.processedPayload.heading_cardinal : '') 
          }, { 
            icon: 'gyro', 
            payloadKey:'elevation_info', 
            value: this.processedPayload.elevation_info ?? '-', 
          }, {
            icon: this.coolantTempIcon,
            title: 'Coolant temperature',
            payloadKey:'engine_coolant_temperature', 
            value: this.processedPayload.engine_coolant_temperature ?? '-', 
          },          
        ]
      ]
    },

    coolantTempIcon() {
      if(this.processedPayload?.engine_coolant_temperature) {
        if(Number(this.processedPayload?.engine_coolant_temperature) > 119) return 'coolant-temp-hot'
        else if(Number(this.processedPayload?.engine_coolant_temperature) > 31) return 'coolant-temp-warm'
        else return 'coolant-temp-cool'
      } return 'coolant-temp'
    },
    malfunctionIcon() {
      if(Number(this.processedPayload?.malfunction_indicator_lamp_status) == 1) return require(`@/assets/images/icons/malfunction-indicator-on.svg`)
      else if(Number(this.processedPayload?.malfunction_indicator_lamp_status) == 2) return require(`@/assets/images/icons/malfunction-indicator-error.svg`)
      else if(Number(this.processedPayload?.malfunction_indicator_lamp_status) == 3) return require(`@/assets/images/icons/malfunction-indicator-unavailable.svg`)
      return require(`@/assets/images/icons/malfunction-indicator-off.svg`)
    },
    malfunctionLabel() {
      if(Number(this.processedPayload?.malfunction_indicator_lamp_status) == 1) return 'On'
      else if(Number(this.processedPayload?.malfunction_indicator_lamp_status) == 2) return 'Error'
      else if(Number(this.processedPayload?.malfunction_indicator_lamp_status) == 3) return 'Unavailable'
      return 'Off'
    },
  },
  methods: {
    parsePayloadKey,
  },
};
</script>
<style>
.custom-col {
  padding: 0.25em 0;
  background-color: #F3F6F9;
}
</style>
