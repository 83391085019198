<template>
  <div>
    <div
      ref="map"
      style="height: 100%"
    />
    <div class="d-none">
      <InfoWindowSite
        ref="info-window-site"
        :site-detail="selectedMarker"
      />
      <InfoWindowPayload
        ref="info-window-payload"
        :dev-mode="devMode"
        :device-detail="deviceDetail"
        :payload="selectedMarker"
      />
    </div>
  </div>
</template>
<script>
import { toRaw } from 'vue';
import InfoWindowPayload from '@/components/tooltip/InfoWindowPayload.vue';
import InfoWindowSite from '@/components/tooltip/InfoWindowSite.vue';

import { getSiteColor, getSiteMarkerIcon } from '@/utils/helper';

export default {
  components: {
    InfoWindowPayload,
    InfoWindowSite,
  },
  props: {
    deviceDetail: {
      type: Object,
      required: true,
    },
    sites: {
      type: Array,
      default: () => []
    },
    payloads: {
      type: Array,
      required: true,
    },
    payloadMeta: {
      type: Object,
      required: true,
    },
    devMode: {
      type: Boolean,
      default: false,
    },
    enableRoute: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      renderedMarkers: [],
      renderedRoutes: [],
      renderedSites: [],
      renderedGeofence: [],
      selectedMarker: {},
      chunkSize: 24, // Google direction API request limit (25) - 1
    };
  },
  computed: {
    infoWindowContent() {
      const infoWindow = this.$refs['info-window-payload']
      return infoWindow.$refs['content']
    },
    infoWindowContentSite() {
      const infoWindow = this.$refs['info-window-site']
      return infoWindow.$refs['content']
    },

    markers() {
      if(this.payloads.length) {
        return this.payloads.map((item) => (this.parseLocation(item)))
      } return [] 
    },

    // Marker grouped per chunkSize (e.g. 24 markers each group)
    groupedMarkers() {
      let processedArray = [];
      if(this.payloads.length) {
        const latLngArray = this.payloads.map((item) => (this.parseLocation(item)))
        for (let i = 0; i < latLngArray.length; i += this.chunkSize) {
          let latLngChunk = latLngArray.slice(i, i + this.chunkSize);
          processedArray.push(latLngChunk);
        }
      }   
      return processedArray;
    },
  },
  watch: {
    markers: {
      deep: true,
      immediate: false,
      handler() {
        if (this.payloadMeta.current_page == 1) {
          this.initMap()
        } else if (this.payloads.length) {
          this.drawPayloadMarkers()
          this.drawRoute()
        }
      }
    },    
    sites: {
      deep: true,
      immediate: false,
      handler() {
        this.drawSiteMarkers()
      }
    },
    enableRoute(val) {
      this.toggleRoute(val)
    }
  },
  mounted () {
    this.initMap()
  },
  methods: {    
    toggleRoute(val) {
      if (val) {
        this.renderedRoutes.forEach(item => item.setMap(payloadMap))
      } else {
        this.renderedRoutes.forEach(item => item.setMap(null))
      }
    },

    initMap() {
      window.payloadMap = new google.maps.Map(this.$refs['map'], {
        center: new google.maps.LatLng(-6.9447703, 110.4975125),
        zoom: 15,
        mapTypeId: google.maps.MapTypeId.ROADMAP,
        mapTypeControl: true,
      })
      // Initiate info window
      window.infoWindow = new google.maps.InfoWindow({
        content: '',
      });

      // Place markers & route to map
      this.renderedMarkers = []
      this.renderedRoutes = []

      if (this.payloads.length) {
        this.drawPayloadMarkers()
        this.drawRoute()
        this.drawSiteMarkers()
      }
      
      const trafficLayer = new google.maps.TrafficLayer();
      trafficLayer.setMap(payloadMap);
    },

    drawPayloadMarkers() {
      // clear existing markers
      if (this.payloadMeta.current_page == 1) {
        this.renderedMarkers.forEach((item) => toRaw(item).setMap(null))
        this.renderedMarkers = []
      }

      // Create gmaps marker instance and put in in an array
      for (let i = this.renderedMarkers.length; i < this.markers.length; i++) {
        let newMarker = new google.maps.Marker({
          position: this.convertToLatLng(this.markers[i]),
          map: window.payloadMap,
          icon: {
            url: require('@/assets/images/googlemaps/fleet/payload.svg'),
            origin: new google.maps.Point(0, 0),
            anchor: new google.maps.Point(16, 16),
          }
        });
        
        // Add event listener to marker on click
        newMarker.addListener("click", () => {
          // Update InfoWindow Content
          this.selectedMarker = this.payloads[i] ?? {}
          window.infoWindow.setContent(this.infoWindowContent)
          // Show InfoWindow
          window.infoWindow.open({
            anchor: newMarker,
            map: window.payloadMap,
          });
        })
        this.renderedMarkers.push(newMarker) 
      }
      // Set latest icon to latest icon
      this.renderedMarkers[0].setIcon({
        url: require('@/assets/images/googlemaps/fleet/fleet-purple.svg'),
        anchor: new google.maps.Point(15, 36),
      })

      // Set Map Center to marker
      window.payloadMap.setCenter({ lat: this.renderedMarkers[0].position.lat(), lng: this.renderedMarkers[0].position.lng()})
    },
    drawSiteMarkers() {
      // clear existing site markers
      this.renderedSites.forEach((item) => toRaw(item).setMap(null))
      this.renderedSites = []
      // clear existing geofence
      this.renderedGeofence.forEach((item) => toRaw(item).setMap(null))
      this.renderedGeofence = []
      if (this.sites.length) {
        for (let i = 0; i < this.sites.length; i++) {
          let newMarker = new google.maps.Marker({
            position: new google.maps.LatLng(Number(this.sites[i].latitude ?? 0), Number(this.sites[i].longitude ?? 0)),
            map: window.payloadMap,
            icon: {
              url: getSiteMarkerIcon(this.sites[i].category),
              origin: new google.maps.Point(0, 0),
              anchor: new google.maps.Point(13, 13),
              scaledSize: new google.maps.Size(25, 25)
            }
          });

          const geofence = this.sites[i].geofence?.coordinates[0]?.map(([lng, lat]) => ({ lat, lng })) || []
          this.drawGeofence(geofence, this.sites[i].category)

          // Add event listener to marker on click
          newMarker.addListener("click", () => {
            // Update InfoWindow Content
            this.selectedMarker = this.sites[i] ?? {}
            window.infoWindow.setContent(this.infoWindowContentSite)
            // Show InfoWindow
            window.infoWindow.open({
              anchor: newMarker,
              map: window.payloadMap,
            });
          })
          this.renderedSites.push(newMarker)
        }
      }
    },

    drawGeofence(geofence, category = null, isParent = false) {
      let newShape = new google.maps.Polygon({
        paths: geofence,
        geodesic: true,
        fillColor: getSiteColor(category),
        fillOpacity: 0.2,
        strokeColor: getSiteColor(category),
        strokeOpacity: 1,
        strokeWeight: isParent ? 4 : 2,
      });
      newShape.setMap(payloadMap)
      this.renderedGeofence.push(newShape)
    },

    drawRoute() {
      // Clear existing route
      if(this.payloadMeta.current_page ==  1) {
        this.renderedRoutes.forEach(item => item.setMap(null))
        this.renderedRoutes = []
      }

      const currentIndex = this.payloadMeta.current_page - 1
      const selectedArray = this.groupedMarkers[currentIndex]

      if(selectedArray.length) {
        let finish = {}
        // Route origin = last item in previous route
        if (currentIndex) {
          const previousArray = this.groupedMarkers[currentIndex - 1]
          finish = previousArray[previousArray.length - 1]
        } else {
          finish = selectedArray[0]
        }

        let lastIndex = selectedArray.length - 1

        let origin = this.convertToLatLng(selectedArray[lastIndex])
        let destination = this.convertToLatLng(finish)
        let waypoints = this.createWaypointsArray(selectedArray.slice(1, lastIndex))

        this.directionApi(origin, destination, waypoints)
          .then((result) => {
            const directionRenderer = new google.maps.DirectionsRenderer({
              suppressMarkers: true,
              polylineOptions: {
                strokeColor: "#4B38B3",
                strokeOpacity: 1.0,
                strokeWeight: 4,
              }
            });
            directionRenderer.setDirections(result)

            if (this.enableRoute) {
              directionRenderer.setMap(payloadMap)
            }
            this.renderedRoutes.push(directionRenderer)
          })
      }
    },

    convertToLatLng(item) {
      if(item.lat && item.lng) {
        return new google.maps.LatLng(item.lat, item.lng)
      }
      return new google.maps.LatLng(0, 0)
    },

    createWaypointsArray(arr) {
      const orderedWaypoints = arr.reverse()
      return orderedWaypoints.map((item) => (
        { location:this.convertToLatLng(item), stopover: false, }
      ))
    },

    parseLocation(payload) {
      if(payload.coordinates) {
        return {
          lat: payload.coordinates[1],
          lng: payload.coordinates[0],
        }
      } else if (payload.location?.coordinates) {
        return {
          lat: payload.location.coordinates[1],
          lng: payload.location.coordinates[0],
        }
      } return {
        lat: 0,
        lng: 0
      }
    },
  
    async directionApi(origin, destination, waypoints) {
      const directionService = new google.maps.DirectionsService();
      try {
        const promise = await directionService.route(
          {
            origin: origin,
            destination: destination,
            waypoints: waypoints,
            optimizeWaypoints: true,
            travelMode: "DRIVING" 
          }
        )
        return promise
      } catch (error) {
        try {
          const simplifiedWaypoints = waypoints.filter(function (v,i) { return !(i%2); })

          const promise = await directionService.route(
            {
              origin: origin,
              destination: destination,
              waypoints: simplifiedWaypoints,
              travelMode: "WALKING"
            }
          )
          return promise
        } catch (error) {
          console.log('No route found')
        }
      }
    },
  },
};
</script>
