<template>
  <div ref="content">
    <h5 class="fw-bold">
      {{ title }}
    </h5>
    <div>
      <table
        class="table-borderless"
        style="max-width: 320px;"
      >
        <tbody>
          <tr>
            <td class="py-1">
              <img
                src="@/assets/images/icons/site-category.svg"
                alt=""
                style="max-height: 20px; max-width: 20px;"
                class="my-auto me-1"
              >
            </td>
            <td class="py-1 text-capitalize">
              {{ siteCategory }}
            </td>
          </tr>
          <tr>
            <td class="py-1">
              <img
                src="@/assets/images/icons/location.svg"
                alt=""
                style="max-height: 20px; max-width: 20px;"
                class="my-auto me-1"
              >
            </td>
            <td class="py-1">
              {{ siteDetail.latitude ?? '' }}, {{ siteDetail.longitude ?? '' }}
            </td>
          </tr>
          <tr v-if="showAddress">
            <td class="py-1">
              <img
                src="@/assets/images/icons/address.svg"
                alt=""
                style="max-height: 20px; max-width: 20px;"
                class="my-auto me-1"
              >
            </td>
            <td class="py-1">
              {{ siteDetail.address ?? '-' }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div
      v-if="showDetailsButton"
      class="mt-2"
    >
      <router-link
        :to="`/organization/${organizationId}/site/${siteDetail.id}?from=dashboard`"
        variant="outline-primary"
        class="btn btn-outline-primary w-100"
      >
        Details
      </router-link>
    </div>
  </div>
</template>

<script>
import { capitalize } from '@/utils/helper';
export default {
  props: {
    siteDetail: {
      type: Object,
      default() {
        return {}
      }
    },
    showDetailsButton: {
      type: Boolean,
      default: true,
    },
    showAddress: {
      type: Boolean,
      default: true,
    }
  },
  computed: {
    title() {
      const code = this.siteDetail?.code ? `${this.siteDetail.code} :` : '' 
      return `${code} ${this.siteDetail.name} `
    },
    organizationId() {
      return this.$store.state.organization.selectedOrganization;
    },
    siteCategory() {
      return this.siteDetail?.category ? this.capitalize(this.siteDetail.category) : ''
    }
  },
  methods: {
    capitalize,
  },
};
</script>