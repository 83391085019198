<template>
  <div class="card-body pt-0">
    <div class="mb-3">
      <div
        class="alert alert-info alert-dismissible alert-label-icon label-arrow shadow mb-0"
        role="alert"
      >
        <i class="ri-error-warning-line label-icon" />The fleets you select will be displayed in sequence from left to right.
      </div>
    </div>
    <div
      v-for="i in 5"
      :key="i"
      class="mb-3"
    >
      <FormInputSelect
        v-model="selectedDeviceIds[i]"
        :options="deviceOptions[i-1]"
        placeholder="Select Fleet"
        :label="`0${i}`"
        :can-clear="true"
      />
    </div>
    <b-row>
      <b-col>
        <b-button
          variant="soft-danger"
          class="w-100"
          @click="resetForm"
        >
          Clear All
        </b-button>
      </b-col>
      <b-col>
        <b-button
          variant="soft-info"
          class="w-100"
          @click="saveForm"
        >
          Apply
        </b-button>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import FormInputSelect from '@/components/FormInputSelect.vue';

export default {
  components: {
    FormInputSelect,
  },
  props: {
    deviceList: {
      type: Array,
      default() {
        return []
      } 
    },
  },
  data() {
    return {
      selectedDeviceIds: {
        1: null,
        2: null,
        3: null,
        4: null,
        5: null,
      },
    };
  },
  computed: {
    selectedOrganization() {
      return this.$store.state.organization.selectedOrganization;
    },
    deviceOptions() {
      const selected = Object.values(this.selectedDeviceIds)
      const deviceListOptions = [];

      for (let i = 1; i <= 5; i++) {
        const result = this.deviceList.filter(item => !selected.includes(item.id))
          .map(item => ({ value: item.id, label: item.vehicle_number }));

        const selectedDevice = this.deviceList.find(item => item.id == this.selectedDeviceIds[i]);
        if(selectedDevice) {
          result.push({ value: selectedDevice.id, label: selectedDevice.vehicle_number })
        }

        deviceListOptions.push(result);
      }
      
      return deviceListOptions;
    },
    selectedDevices() {
      const selectedId = Object.values(this.selectedDeviceIds).filter(val => val !== null);
      return selectedId.map(id => this.deviceList.find(device => device.id == id))
    },
    convoyData() {
      const distance = []
      const fleet = this.selectedDevices
      for (let i = 0; i < fleet.length - 1; i++) {
        distance.push({
          start: fleet[i].id,
          end: fleet[i+1].id,
          distance: this.calculateDistance(fleet[i], fleet[i + 1])
        })
      }
      return distance
    },
  },
  watch: {
    selectedOrganization: {
      immediate: true,
      handler(organizationId) {
        if (organizationId) {
          this.resetForm()
          this.$emit('set-convoy', {
            fleet: this.selectedDevices,
            convoy: this.convoyData,
          })
        }
      },
    },
    convoyData: {
      immediate: false,
      deep: true,
      handler() {
        if(this.selectedDevices.length) {
          this.$emit('set-convoy', {
            fleet: this.selectedDevices,
            convoy: this.convoyData,
            isUpdate: true,
          })
        }
      }
    }
  },
  methods: {
    resetForm() {
      this.selectedDeviceIds = {
        1: null,
        2: null,
        3: null,
        4: null,
        5: null,
      }
      this.saveForm()
    },
    saveForm() {
      this.$emit('set-convoy', {
        fleet: this.selectedDevices,
        convoy: this.convoyData,
        isUpdate: false,
      })

      // Move null value dropdown to bottom
      const nullCount = Object.values(this.selectedDeviceIds).filter(val => val === null).length;
      const newObj = {};
      let i = 1;

      for (const [key, val] of Object.entries(this.selectedDeviceIds)) {
        if (val !== null) {
          newObj[i] = val;
          i++;
        }
      }

      for (let j = i; j <= i + nullCount - 1; j++) {
        newObj[j] = null;
      }

      this.selectedDeviceIds = newObj
    },
    calculateDistance(point1, point2) {
      const lat1 = point1.last_location?.location?.coordinates[0]
      const lon1 = point1.last_location?.location?.coordinates[1]
      const lat2 = point2.last_location?.location?.coordinates[0]
      const lon2 = point2.last_location?.location?.coordinates[1]

      const earthRadius = 6371; // Radius of the Earth in kilometers

      // Convert latitude and longitude from degrees to radians
      const lat1Rad = this.toRadians(lat1);
      const lon1Rad = this.toRadians(lon1);
      const lat2Rad = this.toRadians(lat2);
      const lon2Rad = this.toRadians(lon2);

      // Difference between the latitudes and longitudes
      const latDiff = lat2Rad - lat1Rad;
      const lonDiff = lon2Rad - lon1Rad;

      // Haversine formula
      const a =
        Math.sin(latDiff / 2) ** 2 +
        Math.cos(lat1Rad) * Math.cos(lat2Rad) * Math.sin(lonDiff / 2) ** 2;
      const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

      // Calculate the distance
      const distance = earthRadius * c;

      return (distance * 1000).toFixed(0) ;
    },
    toRadians(degrees) {
      return degrees * (Math.PI / 180);
    }

  },
};
</script>

<style>
.filter-header {
    background-color: #F3F6F9;
  }
.offcanvas-footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  background: #FFFFFF;
  box-shadow: 0px -2px 6px 2px rgba(0, 0, 0, 0.15), 0px 1px 2px rgba(0, 0, 0, 0.3);
}
</style>
