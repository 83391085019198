<template>
  <div>
    <b-row class="d-flex row-padding mb-1 gap-1">
      <b-col class="custom-col h-100">
        <div
          class="ms-2" 
          style="font-size: 16px; font-weight: 600"
        >
          Site Activity
        </div>
      </b-col>
    </b-row>
    <div
      class="table-responsive table thead-light"
      primary-key="id"
      stacked="sm"
      busy="false"
      no-local-sorting=""
      no-sort-reset=""
    >
      <table class="table table-bordered">
        <thead>
          <tr>
            <th
              v-for="(item, index) in tableFields"
              :key="index"
              scope="col"
              class=""
            >
              {{ item.label }}
              <div
                class="fw-normal"
                style="white-space:nowrap"
              >
                {{ item.description }}
              </div>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr 
            v-for="(item, index) in fleetSiteActivity" 
            :key="index"
          >
            <td 
              v-for="(field, idx) in tableFields"
              :key="idx"
              :class="(index != tableFields.length - 1) ? 'fit' : '' "
            >
              <span v-if="field.key == 'created_at'">
                {{ formatDateTime(item.created_at) }}
              </span>
              <span v-if="field.key == 'site_name'">
                <router-link
                  :to="`/organization/${organizationId}/site/${item.place.id}`"
                  class="link-item-table custom-link"
                >
                  {{ item.place.name }}
                </router-link>
              </span>
              <span v-if="field.key == 'site_code'">
                {{ item.place.code }}
              </span>
              <span
                v-if="field.key == 'description'"
                class="text-capitalize"
              >
                {{ item.description }}
              </span>
            </td>
          </tr>
        </tbody>
      </table>
      <div
        v-if="!fleetSiteActivity.length && !isLoading"
        class="text-small text-center mb-3"
      >
        <div>There are no data to show.</div>
        <div>Consider changing the uplink date.</div>
      </div>
    </div>
  </div>
</template>

<script>
import { formatDateTime } from '@/utils/helper';
import { utcOffset } from '@/utils/constant';

export default {
  props: {
    fleetDetail: {
      type: Object,
      default() {
        return {}
      } 
    },
    fleetSiteActivity: {
      type: Array,
      default() {
        return []
      }
    },
  },
  data() {
    return {
      utcOffset,
      isLoading: false,
    }
  },
  computed: {
    organizationId() {
      return this.$store.state.organization.selectedOrganization;
    },
    tableFields() {
      return [
        { key: 'created_at', label: 'Datetime', description: utcOffset},
        { key: 'site_code', label: 'Code', description: 'Site'},
        { key: 'site_name', label: 'Name', description: 'Site'},
        { key: 'description', label: 'Status', description: '-'},
      ]
    },
  },
  methods: {
    formatDateTime,
  },
};
</script>

<style>

</style>