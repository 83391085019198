<template>
  <div class="my-auto w-100">
    <b-row
      v-if="mode == 'dashboard'"
      class="mx-auto w-100"
    >
      <div class="col-xl-5 ps-0 ps-xxl-2">
        <FormInputSelect
          v-model="siteType"
          :options="typeOptions"
          label="Macro Site"
          :has-icon="true"
        />
      </div>
    </b-row>
    <div
      v-else
      class="w-100"
    >
      <div class="d-flex justify-content-between w-100 gap-2">
        <b-col class="d-flex gap-2">
          <b-col>
            <FormDatePicker
              v-model="filter.start_date"
              label="Start"
              placeholder="Select Start Datetime"
            />
          </b-col>
          <b-col>
            <FormDatePicker
              v-model="filter.end_date"
              label="End"
              placeholder="Select End Datetime"
            />
          </b-col>
        </b-col>
        <b-col class="d-flex align-items-end gap-2">
          <b-button
            type="submit"
            variant="primary"
            class="h-100 w-100"
            @click="submitForm"
          >
            Trace
          </b-button>
          <b-button
            type="submit"
            variant="soft-info"
            class="h-100 w-100"
            :disabled="(payloadMeta.current_page == payloadMeta.last_page) || isLoading"
            @click="$emit('load-more')"
          >
            <div class="d-flex gap-2 justify-content-center">
              <span class="my-auto">
                {{ isLoading ? "Loading..." : "Load" }} 
              </span>
              <b-badge
                v-show="!isLoading"
                variant="info"
                class="d-flex text-white my-0"
              >
                <span class="my-auto">
                  {{ payloadMeta.current_page }} / {{ payloadMeta.last_page }}
                </span>
              </b-badge>
            </div>
          </b-button>
          <b-button
            class="h-100 w-100"
            variant="soft-danger"
            @click="resetForm"
          >
            Reset
          </b-button>
          <div
            class="d-flex h-100 w-100 my-auto justify-content-center btn btn-outline-primary"
            @click="showRoute=!showRoute"
          >
            <b-form-checkbox
              id="toggle-route"
              v-model="showRoute"
              type="checkbox"
            />
            <span>Route</span>
          </div>
        </b-col>
      </div>
    </div>
  </div>
</template>
<script>
import FormInputSelect from '@/components/FormInputSelect.vue';
import FormDatePicker from '@/components/FormDatePicker.vue';
import { format } from 'date-fns';

import { siteTypeOptions } from '@/utils/constant';

export default {
  components: {
    FormInputSelect,
    FormDatePicker,
  },
  props: {
    mode: {
      type: String,
      default: 'dashboard'
    },
    isLoading: {
      type: Boolean,
      default: false
    },
    payloadMeta: {
      type: Object,
      default() {
        return {}
      }
    },
    latestTripDate: {
      type: Object,
      default() {
        return {
          start_date: format(new Date(), 'yyyy-MM-dd')+ " 00:00",
          end_date: format(new Date(), 'yyyy-MM-dd') + " 23:59",
        }
      }
    }
  },
  data() {
    return {
      siteType: '',
      typeOptions: [
        { label: 'All Macro Sites', value: "" },
        ...siteTypeOptions
      ],
      filter: {
        start_date: format(new Date(), 'yyyy-MM-dd')+ " 00:00",
        end_date: format(new Date(), 'yyyy-MM-dd') + " 23:59",
      },
      showRoute: true,
    }
  },
  watch: {
    siteType: {
      immediate: false,
      handler(newValue) {
        this.$emit('filter-site', newValue)
      }
    },
    latestTripDate: {
      immediate: false,
      deep: true,
      handler() {
        this.filter.start_date = format(new Date(this.latestTripDate.start_date), 'yyyy-MM-dd HH:mm')
        this.filter.end_date = format(new Date(this.latestTripDate.end_date), 'yyyy-MM-dd HH:mm')
      }
    },
    showRoute(val) {
      this.$emit('set-route-visibility', val)      
    }
  },
  methods: {
    submitForm() {
      this.$emit('set-filter', this.filter)
    },
    resetForm() {
      this.filter.start_date = format(new Date(this.latestTripDate.start_date), 'yyyy-MM-dd HH:mm')
      this.filter.end_date = format(new Date(this.latestTripDate.end_date), 'yyyy-MM-dd HH:mm')
      this.submitForm()
    },
  },
};

</script>

<style lang="scss" scoped>
.btn-check:active+.btn-light,
.btn-check:checked+.btn-light,
.btn-light.active,
.btn-light.dropdown-toggle.show,
.btn-light:active {
    background-color: #F3F6F9;
    color: black;
    box-shadow: none;
    border: 1px solid #CED4DA;
}
.btn-light {
  background-color: #FFFFFF;
  border: 1px solid #F3F6F9;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
}

.form-select {
  border-bottom-left-radius: 0 !important;
  border-top-left-radius: 0 !important;
}

</style>