<template>
  <div>
    <div
      class="table-responsive table thead-light"
      primary-key="id"
      stacked="sm"
      busy="false"
    >
      <table class="table table-bordered table-hover">
        <thead>
          <tr>
            <th
              v-for="(item, index) in tableFields"
              :key="index"
              scope="col"
              class=""
            >
              {{ parsePayloadKey(item).name }}
              <div
                class="fw-normal"
                style="white-space: nowrap"
              >
                <small>
                  {{ parsePayloadKey(item).unit || '-' }}
                </small>
              </div>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr 
            v-for="(item, index) in payloads" 
            :key="index"
          >
            <td 
              v-for="(payloadKey, idx) in tableFields"
              :key="idx"
              :class="(index != tableFields.length - 1) ? 'fit' : '' "
            >
              <span v-if="payloadKey == 'created_at'">
                {{ formatDateTime(item.created_at) }}
              </span>
              <span v-if="payloadKey == 'latitude'">
                {{ parseLocation(item).latitude }}
              </span>
              <span v-if="payloadKey == 'longitude'">
                {{ parseLocation(item).longitude }}
              </span>
              <span v-if="payloadKey == 'snr'">
                {{ Math.round((item.snr * 100))/100 }}
              </span>
              <span v-if="payloadKey != 'created_at' && payloadKey != 'latitude' && payloadKey != 'longitude' && payloadKey != 'snr'">
                {{ item[payloadKey] }}
              </span>
            </td>
          </tr>
        </tbody>
      </table>
      <div
        v-if="!payloads.length && !isLoading"
        class="text-small text-center mb-3"
      >
        <div>There are no payloads to show.</div>
        <div>Consider changing the uplink date.</div>
      </div>
    </div>
  </div>
</template>
<script>
  import { formatDateTime, parsePayloadKey } from '@/utils/helper';

  export default {
    props: {
      payloads: {
        type: Array,
        required: true, 
      },
      payloadKeys: {
        type: Array,
        required: true, 
      },
      isLoading: {
        type: Boolean,
        required: true,
      }
    },
    data() {
      return {}
    },
    computed: {
      tableFields() {
        return this.payloadKeys.map(item => item.key) 
      },
    },
    methods: {
      parsePayloadKey,
      formatDateTime,
      parseLocation(payload) {
        if(payload.coordinates) {
          return {
            latitude: payload.coordinates[1],
            longitude: payload.coordinates[0],
          }
        } else if (payload.location?.coordinates) {
          return {
            latitude: payload.location.coordinates[1],
            longitude: payload.location.coordinates[0],
          }
        } return {
          latitude: '',
          longitude: ''
        }
      }
    },
  };
</script>
